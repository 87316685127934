$main-color: #326fc8;
$main-color-dark: #0a3a88;
$heading-color: #2b599c;
$text-color: #444444;
$image-border-radius: 25px;

$text-size: 16px;

$button-border: 25px;
$button-transition: 0.3s;

html,
body {
  overflow-x: hidden;
  margin: 0;
}

a:hover {
  text-decoration: none;
}

.aboutus,
.contact {
  background: #fff;
}

@media (min-width: 992px) {
  .welcome,
  .aboutus,
  .projects,
  .contact,
  .project {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .welcome,
  .aboutus {
    height: 92vh;
  }

  .contact {
    padding: 7rem 0;
  }
}

.header-container {
  background-color: #fff;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  height: 80px;
}

.header {
  display: flex;
  height: 90px;
  align-items: center;
  width: inherit;
  max-width: inherit;
  text-transform: uppercase;
  font-family: 'Exo 2', sans-serif;

  .goBack {
    background: $main-color;
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    border-radius: $button-border;
    color: #fff;
    cursor: pointer;
    transition: 0.3s;
    font-weight: 600;

    &:hover {
      background: $main-color-dark;
    }
  }

  .header-logo {
    height: 70%;
    width: 160px;
    cursor: pointer;
  }

  .header-nav {
    list-style-type: none;
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-bottom: 0px;

    li {
      display: inline-block;
      margin: 0px 5px;
      padding: 10px 30px;
      cursor: pointer;
      font-size: $text-size;
      color: $text-color;
      font-weight: 600;
      transition: $button-transition;
    }

    li:hover {
      color: $main-color;
    }

    li.highlighted {
      background-color: $main-color;
      color: #fff;
      border-radius: $button-border;

      svg {
        margin-right: 0.5rem;
      }
    }

    li.highlighted:hover {
      background-color: $main-color-dark;
    }
  }
}

$transition-duration: 0.4s;
$transition-delay: 0.05s;
$items: 4;

#toggle-menu {
  display: none;
}

.mobile-header {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 999;

  .goBack {
    font-weight: 700;
    display: flex;
    justify-content: center;
    color: #fff;
    margin-left: auto;
    background: #326fc8;
    border-radius: $button-border;
    padding: 0.5rem 1rem;
    font-size: 14px;
    cursor: pointer;
    pointer-events: all;
    height: 40px;
  }

  #menuBtn {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 30px;
    top: 26px;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    pointer-events: all;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: $heading-color;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.3s ease-in-out;

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 10px;
      }

      &:nth-child(4) {
        top: 20px;
      }
    }

    &.active {
      span {
        background-color: #fff;
        &:nth-child(1) {
          top: 10px;
          width: 0%;
          left: 50%;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          top: 10px;
          width: 0%;
          left: 50%;
        }
      }
    }
  }

  .mobile-logo {
    position: absolute;
    left: 30px;
    top: 17.5px;
  }

  .logo {
    height: 40px;
  }

  .menu {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 20px;
    min-height: 75px;
    pointer-events: none;
  }

  #menuBackground {
    width: 1px;
    height: 1px;
    position: absolute;
    top: 37px;
    right: 40px;
    background: #2b599c;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0, 0.995, 0.99, 1);

    &.active {
      transform: translate(0%, 0%);
      transform: scale(1700);
    }
  }

  #toggle-menu:checked {
    & ~ .nav {
      pointer-events: auto;
      transform: translate(0, 0);

      .nav-item {
        line-height: 40px;
        opacity: 1;
        transition: 0.1s 0.1s;
        color: #000;
        cursor: pointer;
      }
    }
  }
  .button-toggle {
    display: inline-block;
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #000;
  }
}

.nav {
  width: 50%;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  transition: 0.5s;
  margin-bottom: 0;
  margin-top: -50px;

  a {
    display: block;
    width: 100%;
    padding: 30px 0;
  }
}

.nav-item {
  display: block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.6rem;
  color: transparent;
  transition: $transition-duration, opacity 0.1s;
  color: #fff !important;
  opacity: 0;
}

#openBtn {
  transition: 0.25s;
  opacity: 1;
  position: absolute;
  right: 0px;
  top: 25px;
  right: 20px;
}

#closeBtn {
  transition: 0.25s;
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 25px;
  right: 20px;
}

.fadeOut {
  opacity: 0;
}

.fadeIn {
  opacity: 1;
}

#content {
  padding-top: 90px;
  transition: 0.5s;
}

#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;
}

@media (min-width: 769px) {
  #overlay {
    display: none;
  }
}

@media (max-width: 768px) {
  #content {
    padding-top: 60px;
  }
}

@media (max-width: 992px) {
  .header {
    .header-nav {
      li {
        padding: 10px 25px;
      }
    }
  }
}

/* Welcome screen */
.welcome {
  position: relative;
  height: 92vh;

  h1 {
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 4rem;
  }

  h2 {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 2rem;
  }

  .backgroundImage {
    position: fixed;
    width: 100%;
    height: 92vh;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

h1,
h2 {
  color: $heading-color;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 1rem;
  font-family: 'Exo 2', sans-serif;
}

p {
  color: $text-color;
  font-size: 18px;
  padding-bottom: 1rem;
}

@media (max-width: 768px) {
  .welcome {
    .welcome-text {
      align-items: center;
      padding-bottom: 2rem;
    }

    .welcome-img {
      padding: 2rem 0;
    }

    h2,
    p {
      text-align: center;
    }
  }
}

.bedlek-btn {
  width: 12rem;
  cursor: pointer;
  text-align: center;
  padding: 10px 30px;
  font-weight: 700;
  border-radius: $button-border;
  transition: $button-transition;
  color: #fff;
  background-color: $main-color;
}

.bedlek-btn:hover {
  text-decoration: none;
  color: #fff;
  background-color: $main-color-dark;
}

.bedlek-btn.white {
  color: $main-color-dark;
  background-color: #fff;
}

.bedlek-btn.white:hover {
  color: #fff;
  background-color: $main-color-dark;
}

.scrollDown {
  display: block;
  padding: 60px 0;
  text-align: center;
  color: #FFF;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  position: absolute;
  bottom: 3vh;
  left: 50%;
  transform: translate(-50%)
}

.scrollDown span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 2px solid #FFF;
  border-bottom: 2px solid #FFF;
  border-bottom-left-radius: 3px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

/* About us */

.aboutus {
  .aboutus-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  img {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .aboutus-slider {
    padding-top: 2rem;
  }

  .carousel {
    .slider-wrapper {
      border-radius: 25px;
      /*border: 10px solid #2b599c !important;*/
    }
  }
}

@media (max-width: 992px) {
  .aboutus-slider {
    display: none;
  }

  .aboutus-text {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media (max-width: 1182px) {
  .aboutus-slider {
    padding-bottom: 4rem;
  }
}

/* Projects */

.projects {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: rgb(10, 58, 136);
  background: linear-gradient(167deg, rgba(10, 58, 136, 1) 0%, rgba(50, 111, 200, 1) 100%);

  .col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h2 {
      color: #fff;
      padding-bottom: 0rem;
    }

    h4 {
      color: #fff;
      font-size: 18px;
      padding-bottom: 2rem;
      font-weight: 300;
    }

    .white {
      margin-top: 2rem;
    }
  }

  .owl-item {
    display: flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 15px;

    a {
      width: 95%;
      overflow: hidden;
      border-radius: 15px;
    }

    .slide-container {
      width: 100%;
      height: 50vh;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-top: 2rem;
      padding-bottom: 2.3rem;
      transition: 0.5s;
      position: relative;
      overflow: hidden;

      .slider-image {
        height: 35rem;
        width: auto;
      }

      .slide-text-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background: linear-gradient(180deg, rgba(234, 242, 255, 0) 60%, rgba(0, 0, 0, 0.5) 100%);
      }

      .text {
        position: absolute;
        bottom: 1rem;
        padding: 0rem 1rem;
        width: 100%;
        z-index: 1;

        h3 {
          font-size: 22px;
          font-weight: 600;
          color: #fff;
        }

        .year {
          font-size: 20px;
          font-weight: 300;
          color: #fff;
          position: relative;
        }
      }

      &:hover {
        transform: scale(1.02);
      }
    }
  }

  .owl-dots {
    padding-top: 2rem;
    .owl-dot {
      span {
        background: #fff !important;
        margin: 5px 3px !important;
        width: 8px !important;
        height: 8px !important;
        transition: 0.5s !important;
      }
      &.active span {
        width: 50px !important;
      }
    }
  }

  .owl-nav {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    z-index: -1;

    span {
      color: #fff;
      font-size: 50px;
      transition: 0.3s;
    }

    button:hover {
      background-color: transparent !important;

      span {
        color: $main-color-dark;
      }
    }

    .owl-prev {
      float: left;
      transform: translate(-50px, 0);
    }

    .owl-next {
      float: right;
      transform: translate(50px, 0);
    }
  }
}

@media (max-width: 992px) {
  .projects {
    text-align: center;

    .owl-nav {
      display: none;
    }

    .owl-dots {
      padding-bottom: 2rem;
    }

    h4 {
      text-align: center;
    }

    .white {
      margin-top: 0 !important;
    }
  }
}

/* Contact */

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    width: 80%;
    margin-bottom: 0;
  }

  .sou-check {
    width: 100%;
    font-size: 14px;

    .custom-control-label {
      cursor: pointer;
      padding-top: 0.1rem;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #326fc8 !important;
      background-color: #326fc8 !important;
    }
  }

  .error-message {
    color: #a00101;
    font-size: 12px;
    height: 0;
  }

  .spinner {
    margin-left: 40%;
    margin-top: 3rem;
  }

  .messageSent {
    font-size: 14px;
  }

  label {
    font-size: 14px;
    font-weight: 600;
    color: #777;
    width: 100%;
  }

  .contact-form {
    width: 80%;
  }

  input[type="text"],
  textarea {
    display: block;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 7px 15px;
    margin-top: 0.2rem;
    transition: 0.3s;
    outline: none;
    overflow: hidden;
    resize: none;

    &:focus {
      border-color: rgba(50, 111, 200, 0.7);
    }
  }

  input[type="submit"] {
    display: block;
    background: $main-color;
    border: none;
    color: #fff;
    padding: 7px 20px;
    border-radius: $button-border;
    font-weight: 600;
    transition: 0.5s;
    margin-top: 1rem;

    &:hover {
      background: $main-color-dark;
    }
  }
  .contact-icons {
    padding-top: 1rem;
    display: flex;
    width: 100%;

    .contact-line:nth-child(2),
    .contact-line:nth-child(3) {
      a {
        color: #ffc107;
        font-weight: 700;
      }
    }

    .contact-line {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 5rem;
      margin-right: 1rem;
      padding-right: 1.6rem;
      border-right: 1px solid #eee;

      span {
        font-size: 16px;
        color: #777;
        font-weight: 300;
        padding-left: 1rem;

        b {
          font-weight: 700;
        }
      }

      a {
        color: #777;
      }
    }
  }
}

@media (max-width: 992px) {
  .contact-icons {
    display: block !important;

    .contact-line {
      display: block !important;
      width: 100%;
      text-align: center;
      padding-right: 0 !important;
      margin-right: 0 !important;
      height: auto !important;
      border-right: none !important;

      svg {
        width: 50px;
        height: 50px;
      }

      span {
        display: block !important;
        padding: 1rem 0 !important;
        width: 100%;
      }
    }
  }

  .contact-form {
    width: 100% !important;
    padding-bottom: 2rem;
  }

  .contact-text {
    padding-top: 2rem;
    text-align: center;

    p {
      width: 100%;
    }
  }

  .map {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

/* Side Nav */

.sideNav {
  position: fixed;
  top: 50%;
  right: 40px;
  transform: translate(0, -50%);
  z-index: 10;

  &.white {
    a span {
      color: #fff;

      &:after {
        background: #fff;
      }
    }
  }

  span {
    color: #2b599c;
    font-weight: 800;
    font-size: 20px;
    display: block;
    position: relative;
    opacity: 0.4;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      &:after {
        transform: translate(15px, -50%);
      }
    }

    &:after {
      content: "";
      width: 50px;
      height: 25px;
      border-radius: 15px;
      background: #0a3a88;
      position: absolute;
      top: 50%;
      transform: translate(20px, -50%);
      opacity: 0.4;
      transition: 0.5s;
    }

    &.active {
      opacity: 1;

      &:after {
        opacity: 0.8;
        transform: translate(10px, -50%) !important;
      }
    }
  }
}

@media (max-width: 1190px) {
  .sideNav {
    display: none;
  }
}

/* Footer */
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0rem 0rem;
  color: #fff;
  background: rgb(10, 58, 136);
  background: linear-gradient(167deg, rgba(10, 58, 136, 1) 0%, rgba(50, 111, 200, 1) 100%);

  .footer-logo {
    width: 10rem;
  }
}

.footer-copy {
  text-align: center;
  padding: 0.7rem 0;
  color: #0a3a88;
  font-weight: 600;
  font-size: 14px;
  background: #fff;
}

/* Document */

#content .document {
  min-height: 65vh;

  .spinner {
    margin-left: 50%;
    margin-top: 3rem;
  }
}

.document h2 {
  padding-top: 2rem;
}

/* Project */

.projectContent {
  min-height: 73vh;

  .spinner {
    margin-left: 50%;
    padding: 3rem 0;
  }

  .backgroundImage {
    position: fixed;
    width: 100%;
    height: 92vh;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .contentWrapper {
    background: #fff;
  }

  .projectHeader {
    padding: 2rem 0 2rem 0;
    background: #fff;

    h1 {
      font-weight: 700;
      color: #2b599c;
      font-size: 3rem;
    }

    .projectPreviewContent {
      padding-bottom: 0rem;
    }

    .characteristics {
      padding-top: 1rem;

      .heading {
        font-size: 14px;
        color: #333;
        text-transform: none;
        font-weight: 400;
        padding-bottom: 0rem;
      }

      .value {
        display: inline-block;
        color: #2b599c;
        font-weight: 700;
        font-size: 18px !important;
        padding-bottom: 1rem;
      }
    }
  }

  .projectBody {
    padding: 1rem 0 5rem;
    background: #fff;
  }

  .projectImage {
    height: 90.5vh;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;

    a li {
      color: #2b599c;
      background: #fff;
      display: inline-block;
      border-radius: $button-border;
      padding: 0.7rem 2rem;
      font-weight: 700;
      margin-bottom: 5vh;
      cursor: pointer;
      transition: 0.3s;
    }
  }

  @media (max-width: 992px) {
    .projectImage {
      height: 92vh !important;

      li {
        margin-bottom: 7rem !important;
      }
    }

    .characteristics {
      padding-top: 0rem !important;

      .heading {
        padding-top: 1rem;
      }

      .value {
        padding-bottom: 1rem;
      }
    }

    .projectHeader {
      h1 {
        font-size: 28px;
      }
    }
  }

  .galleryImage {
    cursor: pointer;
    width: 100%;
  }
}

/* All Projects */
.allProjectsHeading {
  padding: 1.5rem 0;
  font-size: 24px;
  color: #2b599c;
}

.projectCard {
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:hover {
    .showProjectBtn {
      opacity: 1;
    }

    .imgContainer {
      filter: grayscale(70%);
    }
  }

  .showProjectBtn {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
  }

  h2 {
    width: 95%;
    font-size: 16px;
    font-weight: 500;
    padding-top: 1rem;
    padding-bottom: 0;
    text-transform: none;
  }

  p {
    font-size: 14px;
    width: 95%;
    padding-top: 0;
  }

  .imgContainer {
    width: 100%;
    height: 200px;
    border-radius: 15px;
    overflow: hidden;
    transition: 0.3s;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (max-width: 992px) {
  .projectCard {
    .imgContainer {
      height: 250px;
    }

    h2 {
      font-size: 20px;
      font-weight: 600;
    }

    p {
      font-size: 16px;
    }
  }
}

/* 404 */
.page-not-found {
  background: #0a3a88;
  padding-top: 0 !important;

  div {
    font-family: "VT323", monospace;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    font-size: 24px;
    color: #fff;
  }
  .error {
    font-size: 42px;
    color: #2b599c;
    background: #fff;
    padding: 0.5rem 2rem;
    margin-bottom: 1rem;
  }

  .back {
    cursor: pointer;
    color: #ffc107;
    font-size: 28px;
    margin-top: 2rem;
  }

  .foot {
    margin-top: 3rem;
  }
}

@media (max-width: 992px) {
  .page-not-found {
    div {
      font-size: 20px;
      text-align: center;
    }

    .error {
      font-size: 34px;
    }

    .back {
      font-size: 24px;
    }
  }
}
