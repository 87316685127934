@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;600;700;800&display=swap);
html,
body {
  overflow-x: hidden;
  margin: 0; }

a:hover {
  text-decoration: none; }

.aboutus,
.contact {
  background: #fff; }

@media (min-width: 992px) {
  .welcome,
  .aboutus,
  .projects,
  .contact,
  .project {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .welcome,
  .aboutus {
    height: 92vh; }
  .contact {
    padding: 7rem 0; } }

.header-container {
  background-color: #fff;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  height: 80px; }

.header {
  display: -webkit-flex;
  display: flex;
  height: 90px;
  -webkit-align-items: center;
          align-items: center;
  width: inherit;
  max-width: inherit;
  text-transform: uppercase;
  font-family: 'Exo 2', sans-serif; }
  .header .goBack {
    background: #326fc8;
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    border-radius: 25px;
    color: #fff;
    cursor: pointer;
    transition: 0.3s;
    font-weight: 600; }
    .header .goBack:hover {
      background: #0a3a88; }
  .header .header-logo {
    height: 70%;
    width: 160px;
    cursor: pointer; }
  .header .header-nav {
    list-style-type: none;
    margin-left: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 0px; }
    .header .header-nav li {
      display: inline-block;
      margin: 0px 5px;
      padding: 10px 30px;
      cursor: pointer;
      font-size: 16px;
      color: #444444;
      font-weight: 600;
      transition: 0.3s; }
    .header .header-nav li:hover {
      color: #326fc8; }
    .header .header-nav li.highlighted {
      background-color: #326fc8;
      color: #fff;
      border-radius: 25px; }
      .header .header-nav li.highlighted svg {
        margin-right: 0.5rem; }
    .header .header-nav li.highlighted:hover {
      background-color: #0a3a88; }

#toggle-menu {
  display: none; }

.mobile-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  width: 100%;
  z-index: 999; }
  .mobile-header .goBack {
    font-weight: 700;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: #fff;
    margin-left: auto;
    background: #326fc8;
    border-radius: 25px;
    padding: 0.5rem 1rem;
    font-size: 14px;
    cursor: pointer;
    pointer-events: all;
    height: 40px; }
  .mobile-header #menuBtn {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 30px;
    top: 26px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    pointer-events: all; }
    .mobile-header #menuBtn span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #2b599c;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      transition: 0.3s ease-in-out; }
      .mobile-header #menuBtn span:nth-child(1) {
        top: 0px; }
      .mobile-header #menuBtn span:nth-child(2), .mobile-header #menuBtn span:nth-child(3) {
        top: 10px; }
      .mobile-header #menuBtn span:nth-child(4) {
        top: 20px; }
    .mobile-header #menuBtn.active span {
      background-color: #fff; }
      .mobile-header #menuBtn.active span:nth-child(1) {
        top: 10px;
        width: 0%;
        left: 50%; }
      .mobile-header #menuBtn.active span:nth-child(2) {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
      .mobile-header #menuBtn.active span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
      .mobile-header #menuBtn.active span:nth-child(4) {
        top: 10px;
        width: 0%;
        left: 50%; }
  .mobile-header .mobile-logo {
    position: absolute;
    left: 30px;
    top: 17.5px; }
  .mobile-header .logo {
    height: 40px; }
  .mobile-header .menu {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 20px;
    min-height: 75px;
    pointer-events: none; }
  .mobile-header #menuBackground {
    width: 1px;
    height: 1px;
    position: absolute;
    top: 37px;
    right: 40px;
    background: #2b599c;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0, 0.995, 0.99, 1); }
    .mobile-header #menuBackground.active {
      -webkit-transform: translate(0%, 0%);
              transform: translate(0%, 0%);
      -webkit-transform: scale(1700);
              transform: scale(1700); }
  .mobile-header #toggle-menu:checked ~ .nav {
    pointer-events: auto;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
    .mobile-header #toggle-menu:checked ~ .nav .nav-item {
      line-height: 40px;
      opacity: 1;
      transition: 0.1s 0.1s;
      color: #000;
      cursor: pointer; }
  .mobile-header .button-toggle {
    display: inline-block;
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #000; }

.nav {
  width: 50%;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  pointer-events: none;
  transition: 0.5s;
  margin-bottom: 0;
  margin-top: -50px; }
  .nav a {
    display: block;
    width: 100%;
    padding: 30px 0; }

.nav-item {
  display: block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.6rem;
  color: transparent;
  transition: 0.4s, opacity 0.1s;
  color: #fff !important;
  opacity: 0; }

#openBtn {
  transition: 0.25s;
  opacity: 1;
  position: absolute;
  right: 0px;
  top: 25px;
  right: 20px; }

#closeBtn {
  transition: 0.25s;
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 25px;
  right: 20px; }

.fadeOut {
  opacity: 0; }

.fadeIn {
  opacity: 1; }

#content {
  padding-top: 90px;
  transition: 0.5s; }

#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  opacity: 0;
  transition: 0.5s;
  pointer-events: none; }

@media (min-width: 769px) {
  #overlay {
    display: none; } }

@media (max-width: 768px) {
  #content {
    padding-top: 60px; } }

@media (max-width: 992px) {
  .header .header-nav li {
    padding: 10px 25px; } }

/* Welcome screen */
.welcome {
  position: relative;
  height: 92vh; }
  .welcome h1 {
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 4rem; }
  .welcome h2 {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 2rem; }
  .welcome .backgroundImage {
    position: fixed;
    width: 100%;
    height: 92vh;
    z-index: -1; }
    .welcome .backgroundImage img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

h1,
h2 {
  color: #2b599c;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 1rem;
  font-family: 'Exo 2', sans-serif; }

p {
  color: #444444;
  font-size: 18px;
  padding-bottom: 1rem; }

@media (max-width: 768px) {
  .welcome .welcome-text {
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 2rem; }
  .welcome .welcome-img {
    padding: 2rem 0; }
  .welcome h2,
  .welcome p {
    text-align: center; } }

.bedlek-btn {
  width: 12rem;
  cursor: pointer;
  text-align: center;
  padding: 10px 30px;
  font-weight: 700;
  border-radius: 25px;
  transition: 0.3s;
  color: #fff;
  background-color: #326fc8; }

.bedlek-btn:hover {
  text-decoration: none;
  color: #fff;
  background-color: #0a3a88; }

.bedlek-btn.white {
  color: #0a3a88;
  background-color: #fff; }

.bedlek-btn.white:hover {
  color: #fff;
  background-color: #0a3a88; }

.scrollDown {
  display: block;
  padding: 60px 0;
  text-align: center;
  color: #FFF;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  position: absolute;
  bottom: 3vh;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%); }

.scrollDown span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 2px solid #FFF;
  border-bottom: 2px solid #FFF;
  border-bottom-left-radius: 3px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box; }

@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0; } }

@keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
            transform: rotate(-45deg) translate(0, 0);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
            transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0; } }

/* About us */
.aboutus .aboutus-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center; }

.aboutus img {
  width: 100%; }

@media (max-width: 768px) {
  .aboutus-slider {
    padding-top: 2rem; }
  .carousel .slider-wrapper {
    border-radius: 25px;
    /*border: 10px solid #2b599c !important;*/ } }

@media (max-width: 992px) {
  .aboutus-slider {
    display: none; }
  .aboutus-text {
    padding-top: 5rem;
    padding-bottom: 5rem; } }

@media (max-width: 1182px) {
  .aboutus-slider {
    padding-bottom: 4rem; } }

/* Projects */
.projects {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: #0a3a88;
  background: linear-gradient(167deg, #0a3a88 0%, #326fc8 100%); }
  .projects .col {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .projects .col h2 {
      color: #fff;
      padding-bottom: 0rem; }
    .projects .col h4 {
      color: #fff;
      font-size: 18px;
      padding-bottom: 2rem;
      font-weight: 300; }
    .projects .col .white {
      margin-top: 2rem; }
  .projects .owl-item {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    border-radius: 15px; }
    .projects .owl-item a {
      width: 95%;
      overflow: hidden;
      border-radius: 15px; }
    .projects .owl-item .slide-container {
      width: 100%;
      height: 50vh;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      padding-top: 2rem;
      padding-bottom: 2.3rem;
      transition: 0.5s;
      position: relative;
      overflow: hidden; }
      .projects .owl-item .slide-container .slider-image {
        height: 35rem;
        width: auto; }
      .projects .owl-item .slide-container .slide-text-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background: linear-gradient(180deg, rgba(234, 242, 255, 0) 60%, rgba(0, 0, 0, 0.5) 100%); }
      .projects .owl-item .slide-container .text {
        position: absolute;
        bottom: 1rem;
        padding: 0rem 1rem;
        width: 100%;
        z-index: 1; }
        .projects .owl-item .slide-container .text h3 {
          font-size: 22px;
          font-weight: 600;
          color: #fff; }
        .projects .owl-item .slide-container .text .year {
          font-size: 20px;
          font-weight: 300;
          color: #fff;
          position: relative; }
      .projects .owl-item .slide-container:hover {
        -webkit-transform: scale(1.02);
                transform: scale(1.02); }
  .projects .owl-dots {
    padding-top: 2rem; }
    .projects .owl-dots .owl-dot span {
      background: #fff !important;
      margin: 5px 3px !important;
      width: 8px !important;
      height: 8px !important;
      transition: 0.5s !important; }
    .projects .owl-dots .owl-dot.active span {
      width: 50px !important; }
  .projects .owl-nav {
    position: absolute;
    top: 35%;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    width: 100%;
    z-index: -1; }
    .projects .owl-nav span {
      color: #fff;
      font-size: 50px;
      transition: 0.3s; }
    .projects .owl-nav button:hover {
      background-color: transparent !important; }
      .projects .owl-nav button:hover span {
        color: #0a3a88; }
    .projects .owl-nav .owl-prev {
      float: left;
      -webkit-transform: translate(-50px, 0);
              transform: translate(-50px, 0); }
    .projects .owl-nav .owl-next {
      float: right;
      -webkit-transform: translate(50px, 0);
              transform: translate(50px, 0); }

@media (max-width: 992px) {
  .projects {
    text-align: center; }
    .projects .owl-nav {
      display: none; }
    .projects .owl-dots {
      padding-bottom: 2rem; }
    .projects h4 {
      text-align: center; }
    .projects .white {
      margin-top: 0 !important; } }

/* Contact */
.contact {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .contact p {
    width: 80%;
    margin-bottom: 0; }
  .contact .sou-check {
    width: 100%;
    font-size: 14px; }
    .contact .sou-check .custom-control-label {
      cursor: pointer;
      padding-top: 0.1rem; }
    .contact .sou-check .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #326fc8 !important;
      background-color: #326fc8 !important; }
  .contact .error-message {
    color: #a00101;
    font-size: 12px;
    height: 0; }
  .contact .spinner {
    margin-left: 40%;
    margin-top: 3rem; }
  .contact .messageSent {
    font-size: 14px; }
  .contact label {
    font-size: 14px;
    font-weight: 600;
    color: #777;
    width: 100%; }
  .contact .contact-form {
    width: 80%; }
  .contact input[type="text"],
  .contact textarea {
    display: block;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 7px 15px;
    margin-top: 0.2rem;
    transition: 0.3s;
    outline: none;
    overflow: hidden;
    resize: none; }
    .contact input[type="text"]:focus,
    .contact textarea:focus {
      border-color: rgba(50, 111, 200, 0.7); }
  .contact input[type="submit"] {
    display: block;
    background: #326fc8;
    border: none;
    color: #fff;
    padding: 7px 20px;
    border-radius: 25px;
    font-weight: 600;
    transition: 0.5s;
    margin-top: 1rem; }
    .contact input[type="submit"]:hover {
      background: #0a3a88; }
  .contact .contact-icons {
    padding-top: 1rem;
    display: -webkit-flex;
    display: flex;
    width: 100%; }
    .contact .contact-icons .contact-line:nth-child(2) a,
    .contact .contact-icons .contact-line:nth-child(3) a {
      color: #ffc107;
      font-weight: 700; }
    .contact .contact-icons .contact-line {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      height: 5rem;
      margin-right: 1rem;
      padding-right: 1.6rem;
      border-right: 1px solid #eee; }
      .contact .contact-icons .contact-line span {
        font-size: 16px;
        color: #777;
        font-weight: 300;
        padding-left: 1rem; }
        .contact .contact-icons .contact-line span b {
          font-weight: 700; }
      .contact .contact-icons .contact-line a {
        color: #777; }

@media (max-width: 992px) {
  .contact-icons {
    display: block !important; }
    .contact-icons .contact-line {
      display: block !important;
      width: 100%;
      text-align: center;
      padding-right: 0 !important;
      margin-right: 0 !important;
      height: auto !important;
      border-right: none !important; }
      .contact-icons .contact-line svg {
        width: 50px;
        height: 50px; }
      .contact-icons .contact-line span {
        display: block !important;
        padding: 1rem 0 !important;
        width: 100%; }
  .contact-form {
    width: 100% !important;
    padding-bottom: 2rem; }
  .contact-text {
    padding-top: 2rem;
    text-align: center; }
    .contact-text p {
      width: 100%; }
  .map {
    padding-right: 0px !important;
    padding-left: 0px !important; } }

/* Side Nav */
.sideNav {
  position: fixed;
  top: 50%;
  right: 40px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  z-index: 10; }
  .sideNav.white a span {
    color: #fff; }
    .sideNav.white a span:after {
      background: #fff; }
  .sideNav span {
    color: #2b599c;
    font-weight: 800;
    font-size: 20px;
    display: block;
    position: relative;
    opacity: 0.4;
    cursor: pointer;
    transition: 0.5s; }
    .sideNav span:hover:after {
      -webkit-transform: translate(15px, -50%);
              transform: translate(15px, -50%); }
    .sideNav span:after {
      content: "";
      width: 50px;
      height: 25px;
      border-radius: 15px;
      background: #0a3a88;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(20px, -50%);
              transform: translate(20px, -50%);
      opacity: 0.4;
      transition: 0.5s; }
    .sideNav span.active {
      opacity: 1; }
      .sideNav span.active:after {
        opacity: 0.8;
        -webkit-transform: translate(10px, -50%) !important;
                transform: translate(10px, -50%) !important; }

@media (max-width: 1190px) {
  .sideNav {
    display: none; } }

/* Footer */
.footer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0rem 0rem;
  color: #fff;
  background: #0a3a88;
  background: linear-gradient(167deg, #0a3a88 0%, #326fc8 100%); }
  .footer .footer-logo {
    width: 10rem; }

.footer-copy {
  text-align: center;
  padding: 0.7rem 0;
  color: #0a3a88;
  font-weight: 600;
  font-size: 14px;
  background: #fff; }

/* Document */
#content .document {
  min-height: 65vh; }
  #content .document .spinner {
    margin-left: 50%;
    margin-top: 3rem; }

.document h2 {
  padding-top: 2rem; }

/* Project */
.projectContent {
  min-height: 73vh; }
  .projectContent .spinner {
    margin-left: 50%;
    padding: 3rem 0; }
  .projectContent .backgroundImage {
    position: fixed;
    width: 100%;
    height: 92vh;
    z-index: -1; }
    .projectContent .backgroundImage img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .projectContent .contentWrapper {
    background: #fff; }
  .projectContent .projectHeader {
    padding: 2rem 0 2rem 0;
    background: #fff; }
    .projectContent .projectHeader h1 {
      font-weight: 700;
      color: #2b599c;
      font-size: 3rem; }
    .projectContent .projectHeader .projectPreviewContent {
      padding-bottom: 0rem; }
    .projectContent .projectHeader .characteristics {
      padding-top: 1rem; }
      .projectContent .projectHeader .characteristics .heading {
        font-size: 14px;
        color: #333;
        text-transform: none;
        font-weight: 400;
        padding-bottom: 0rem; }
      .projectContent .projectHeader .characteristics .value {
        display: inline-block;
        color: #2b599c;
        font-weight: 700;
        font-size: 18px !important;
        padding-bottom: 1rem; }
  .projectContent .projectBody {
    padding: 1rem 0 5rem;
    background: #fff; }
  .projectContent .projectImage {
    height: 90.5vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    .projectContent .projectImage a li {
      color: #2b599c;
      background: #fff;
      display: inline-block;
      border-radius: 25px;
      padding: 0.7rem 2rem;
      font-weight: 700;
      margin-bottom: 5vh;
      cursor: pointer;
      transition: 0.3s; }
  @media (max-width: 992px) {
    .projectContent .projectImage {
      height: 92vh !important; }
      .projectContent .projectImage li {
        margin-bottom: 7rem !important; }
    .projectContent .characteristics {
      padding-top: 0rem !important; }
      .projectContent .characteristics .heading {
        padding-top: 1rem; }
      .projectContent .characteristics .value {
        padding-bottom: 1rem; }
    .projectContent .projectHeader h1 {
      font-size: 28px; } }
  .projectContent .galleryImage {
    cursor: pointer;
    width: 100%; }

/* All Projects */
.allProjectsHeading {
  padding: 1.5rem 0;
  font-size: 24px;
  color: #2b599c; }

.projectCard {
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  position: relative; }
  .projectCard:hover .showProjectBtn {
    opacity: 1; }
  .projectCard:hover .imgContainer {
    -webkit-filter: grayscale(70%);
            filter: grayscale(70%); }
  .projectCard .showProjectBtn {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 30%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    transition: 0.3s; }
  .projectCard h2 {
    width: 95%;
    font-size: 16px;
    font-weight: 500;
    padding-top: 1rem;
    padding-bottom: 0;
    text-transform: none; }
  .projectCard p {
    font-size: 14px;
    width: 95%;
    padding-top: 0; }
  .projectCard .imgContainer {
    width: 100%;
    height: 200px;
    border-radius: 15px;
    overflow: hidden;
    transition: 0.3s; }
    .projectCard .imgContainer img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

@media (max-width: 992px) {
  .projectCard .imgContainer {
    height: 250px; }
  .projectCard h2 {
    font-size: 20px;
    font-weight: 600; }
  .projectCard p {
    font-size: 16px; } }

/* 404 */
.page-not-found {
  background: #0a3a88;
  padding-top: 0 !important; }
  .page-not-found div {
    font-family: "VT323", monospace;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    min-height: 100vh;
    font-size: 24px;
    color: #fff; }
  .page-not-found .error {
    font-size: 42px;
    color: #2b599c;
    background: #fff;
    padding: 0.5rem 2rem;
    margin-bottom: 1rem; }
  .page-not-found .back {
    cursor: pointer;
    color: #ffc107;
    font-size: 28px;
    margin-top: 2rem; }
  .page-not-found .foot {
    margin-top: 3rem; }

@media (max-width: 992px) {
  .page-not-found div {
    font-size: 20px;
    text-align: center; }
  .page-not-found .error {
    font-size: 34px; }
  .page-not-found .back {
    font-size: 24px; } }

/* Font sizes */
p {
  font-size: 16px; }

body {
  font-family: 'Nunito', sans-serif;
  font-weight: 400; }

* {
  padding: 0;
  margin: 0; }

.admin:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0a3a88;
  z-index: -1; }

.loginFormContainer {
  margin-top: 50vh;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }
  .loginFormContainer .col {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .loginFormContainer .loginForm {
    width: 350px;
    padding: 3rem;
    border-radius: 15px;
    background-color: #FFF;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .loginFormContainer form {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .loginFormContainer .login-logo {
    width: 250px;
    padding-bottom: 1rem; }
  .loginFormContainer input[type=text], .loginFormContainer input[type=password] {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 7px 15px;
    margin-top: 1rem;
    color: #777;
    outline: none;
    transition: 0.3s; }
  .loginFormContainer input[type=text]:focus, .loginFormContainer input[type=password]:focus {
    border: 1px solid #326fc8; }
  .loginFormContainer input[type=submit] {
    width: 100%;
    background-color: #0a3a88;
    color: #FFF;
    border-radius: 15px;
    margin-top: 1rem;
    padding: 7px 15px;
    border: none;
    font-weight: bold;
    transition: 0.3s; }
  .loginFormContainer .disabled {
    background: #777 !important;
    pointer-events: none; }
  .loginFormContainer input[type=submit]:hover {
    background-color: #326fc8; }
  .loginFormContainer .spinner {
    position: absolute !important;
    bottom: 15px; }

/* Spinner */
.spinner {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px; }

.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 4px solid #0a3a88;
  border-radius: 50%;
  -webkit-animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0a3a88 transparent transparent transparent; }

.white-spinner div {
  border-color: #FFF transparent transparent transparent !important; }

.spinner div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s; }

.spinner div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }

.spinner div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s; }

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.admin {
  padding: 2rem 0;
  background-color: #0a3a88; }
  .admin .dashboardContainer {
    background: #bbd5ff;
    background: linear-gradient(237deg, #bbd5ff 0%, #e5f0ff 100%);
    border-radius: 25px;
    padding: 2rem 2rem 0 0;
    min-height: 90vh;
    /* Tiez */ }

.dashboardMenu {
  color: #777;
  font-weight: 600;
  font-size: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 2rem;
  height: 200px; }
  .dashboardMenu ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0; }
    .dashboardMenu ul li {
      padding: 0.6rem 0rem 0.7rem 2rem;
      position: relative;
      transition: 0.3s;
      cursor: pointer; }
      .dashboardMenu ul li svg {
        margin-right: 1rem; }
      .dashboardMenu ul li.active {
        color: #333; }
        .dashboardMenu ul li.active:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 5px;
          background: #24262a; }

.dashboardHeader .dashboardLogo {
  width: 12rem;
  padding-left: 2rem; }

.dashboardContent {
  padding-top: 2rem; }

.projectsTab, .messagesTab {
  border-radius: 7px; }
  .projectsTab .dataTable, .messagesTab .dataTable {
    list-style-type: none;
    width: 100%;
    border-spacing: 0 0.5rem;
    border-collapse: separate;
    position: relative; }
    .projectsTab .dataTable tr th, .messagesTab .dataTable tr th {
      padding: 0.3rem 1rem;
      color: #3d3d3d; }
    .projectsTab .dataTable tr td, .messagesTab .dataTable tr td {
      background: #ecf4ff;
      padding: 0.7rem 1rem;
      cursor: pointer;
      transition: 0.3s;
      font-size: 14px;
      font-weight: 600; }
    .projectsTab .dataTable tr td:first-child, .messagesTab .dataTable tr td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px; }
    .projectsTab .dataTable tr td:last-child, .messagesTab .dataTable tr td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px; }
    .projectsTab .dataTable tr:hover td, .messagesTab .dataTable tr:hover td {
      background: #fafcff; }

.editProject {
  padding-bottom: 1rem; }
  .editProject form {
    margin-top: 1rem;
    width: 100%;
    font-size: 14px; }
  .editProject .charWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 0.5rem; }
    .editProject .charWrapper select {
      margin-bottom: 0 !important; }
  .editProject .newChar, .editProject .deleteChar {
    background: #0a3a88;
    padding: 0.5rem 0.6rem;
    border-radius: 15px;
    cursor: pointer;
    transition: 0.3s;
    display: inline-block;
    margin-left: 0.5rem; }
    .editProject .newChar:hover, .editProject .deleteChar:hover {
      background: #FFF; }
      .editProject .newChar:hover svg, .editProject .deleteChar:hover svg {
        color: #0a3a88; }
    .editProject .newChar svg, .editProject .deleteChar svg {
      color: #FFF;
      transition: 0.3s;
      font-size: 18px; }
  .editProject .deleteChar {
    background: #920c00; }
    .editProject .deleteChar:hover svg {
      color: #920c00; }
  .editProject input[type=text], .editProject input[type=number], .editProject textarea, .editProject select {
    display: block;
    width: 50%;
    background: rgba(255, 255, 255, 0.746);
    border: 1px solid #a1a1a1;
    margin-bottom: 0.5rem;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    font-size: 14px;
    outline: none;
    transition: border 0.3s;
    color: #24262a;
    -moz-appearance: textfield; }
    .editProject input[type=text]:focus, .editProject input[type=number]:focus, .editProject textarea:focus, .editProject select:focus {
      border: 1px solid #0a3a88; }
  .editProject input::-webkit-outer-spin-button,
  .editProject input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .editProject input[type=submit] {
    display: block;
    width: 25%;
    background: #0a3a88;
    color: #FFF;
    margin: 1rem 0;
    transition: 0.3s;
    padding: 0.3rem 1rem;
    border-radius: 10px;
    border: none; }
    .editProject input[type=submit]:hover {
      background: #FFF;
      color: #0a3a88; }
  .editProject .isShownLabel {
    padding-right: 1rem; }
  .editProject .projectPhotos {
    margin-top: 0.5rem;
    width: 50%; }
    .editProject .projectPhotos .projectPhotoContainer {
      display: inline-block;
      position: relative; }
      .editProject .projectPhotos .projectPhotoContainer .deleteButton {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background-color: #3d3d3d;
        position: absolute;
        right: 0px;
        cursor: pointer;
        transition: 0.3s; }
        .editProject .projectPhotos .projectPhotoContainer .deleteButton svg {
          color: #FFF;
          height: 7px;
          transition: 0.3s;
          margin-top: -7.5px;
          margin-left: 0.5px; }
        .editProject .projectPhotos .projectPhotoContainer .deleteButton:hover {
          background-color: #FFF; }
          .editProject .projectPhotos .projectPhotoContainer .deleteButton:hover svg {
            color: #3d3d3d; }
    .editProject .projectPhotos .photo {
      width: 50px;
      height: 50px;
      object-fit: cover;
      margin: 0.2rem;
      border-radius: 10px;
      cursor: pointer; }
      .editProject .projectPhotos .photo.selected {
        outline: 3px solid #0a3a88; }
  .editProject .photoUploadBtn {
    width: 25%;
    background: #24262a;
    color: #FFF;
    border-radius: 10px;
    padding: 0.3rem 1rem;
    border: none;
    transition: 0.3s;
    margin-top: 0.5rem; }
    .editProject .photoUploadBtn:hover {
      background: #777; }
  .editProject h4 {
    font-size: 1em;
    font-weight: 600; }

.modal-dialog {
  position: absolute;
  top: 25%;
  left: 40%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%); }

.deleteProjectBtn {
  color: #920c00;
  cursor: pointer; }

.deleteProjectBtn:hover {
  text-decoration: underline; }

.backButton {
  cursor: pointer;
  color: #0a3a88;
  transition: 0.3s;
  padding-right: 6rem; }

.backButton:hover {
  color: #326fc8; }

.newProjectBtn {
  float: right;
  background: #0a3a88;
  color: #FFF;
  font-weight: 600;
  padding: 0.4rem 1rem;
  font-size: 14px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s; }
  .newProjectBtn:hover {
    background: #2b599c; }

.uploadingProject {
  width: 50%;
  position: relative; }
  .uploadingProject .spinner {
    margin-left: 50%;
    margin-bottom: 1rem; }
  .uploadingProject p {
    padding-bottom: 0rem; }

.pagination {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }
  .pagination .prevPage, .pagination .nextPage {
    padding: 0.4rem 1rem;
    background: #3d3d3d;
    color: #FFF;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    transition: 0.3s; }
    .pagination .prevPage:hover, .pagination .nextPage:hover {
      background: #2b599c; }
  .pagination .actualPage {
    padding: 0.4rem 0.7rem;
    margin: 0 0.5rem;
    font-size: 14px;
    background: #3d3d3d;
    color: #FFF;
    border-radius: 10px; }

.messageDetail .senderName {
  padding-top: 1rem; }

.messageDetail .sendedAt {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0; }

.messageDetail .senderMail, .messageDetail .senderTel {
  padding: 0;
  margin: 0; }
  .messageDetail .senderMail a, .messageDetail .senderTel a {
    font-weight: 600; }

.messageDetail .senderMessage {
  background: #ecf4ff;
  border-radius: 10px;
  padding: 1rem;
  display: inline-block;
  margin-top: 1rem; }

