$main-color: #326fc8;
$main-color-dark: #0a3a88;
$heading-color: #2b599c;
$text-color: #444444;

* {
    padding: 0;
    margin: 0;
}

.admin:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $main-color-dark;
    z-index: -1;
}

.loginFormContainer {
    margin-top: 50vh;
    transform: translate(0, -50%);

    .col {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .loginForm {
        width: 350px;
        padding: 3rem;
        border-radius: 15px;
        background-color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .login-logo {
        width: 250px;
        padding-bottom: 1rem;
    }

    input[type=text], input[type=password] {
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 15px;
        padding: 7px 15px;
        margin-top: 1rem;
        color: #777;
        outline: none;
        transition: 0.3s;
    }

    input[type=text]:focus, input[type=password]:focus {
        border: 1px solid $main-color;
    }

    input[type=submit] {
        width: 100%;
        background-color: $main-color-dark;
        color: #FFF;
        border-radius: 15px;
        margin-top: 1rem;
        padding: 7px 15px;
        border: none;
        font-weight: bold;
        transition: 0.3s;
    }

    .disabled {
        background: #777 !important;
        pointer-events: none;
    }

    input[type=submit]:hover {
        background-color: $main-color;
    }

    .spinner {
        position: absolute !important;
        bottom: 15px;
    }
}

/* Spinner */
.spinner {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
  }
  .spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 4px solid $main-color-dark;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $main-color-dark transparent transparent transparent;
  }

  .white-spinner div {
    border-color: #FFF transparent transparent transparent !important;
}

  .spinner div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .spinner div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .spinner div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.admin {
  padding: 2rem 0;
  background-color: $main-color-dark;

    .dashboardContainer {
        background: rgb(187,213,255);
        background: linear-gradient(237deg, rgba(187,213,255,1) 0%, rgba(229,240,255,1) 100%);
        border-radius: 25px;
        padding: 2rem 2rem 0 0;
        min-height: 90vh; /* Tiez */
    }
}

.dashboardMenu {
    color: #777;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2rem;
    height: 200px;

    ul {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
            padding: 0.6rem 0rem 0.7rem 2rem;
            position: relative;
            transition: 0.3s;
            cursor: pointer;

            svg {
                margin-right: 1rem;
            }

            &.active {
                color: #333;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 5px;
                    background: #24262a;
                }
            }
        }
    }
}

.dashboardHeader {
    .dashboardLogo {
        width: 12rem;
        padding-left: 2rem;
    }
}

.dashboardContent {
    padding-top: 2rem;
}

.projectsTab, .messagesTab {
    
    border-radius: 7px;
    
    .dataTable {
        list-style-type: none;
        width: 100%;
        border-spacing: 0 0.5rem;
        border-collapse: separate;
        position: relative;

        tr {
            th {
                padding: 0.3rem 1rem;
                color: #3d3d3d;
            }

            td {
                background: rgb(236, 244, 255); 
                padding: 0.7rem 1rem;
                cursor: pointer;
                transition: 0.3s;
                font-size: 14px;
                font-weight: 600;
            }

            td:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            td:last-child {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            &:hover {
                td {
                    background: rgb(250, 252, 255);
                }
            }
        }
    }
}

.editProject {
  padding-bottom: 1rem;

    form {
        margin-top: 1rem;
        width: 100%;
        font-size: 14px;
    }

    .charWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      select {
        margin-bottom: 0 !important;
      }
    }
    
    .newChar, .deleteChar {
      background: #0a3a88;
      padding: 0.5rem 0.6rem;
      border-radius: 15px;
      cursor: pointer;
      transition: 0.3s;
      display: inline-block;
      margin-left: 0.5rem;

      &:hover {
        background: #FFF;

        svg {
          color: #0a3a88;
        }
      }

      svg {
        color: #FFF;
        transition: 0.3s;
        font-size: 18px;
      }
    }

    .deleteChar {
      background: #920c00;

      &:hover {
        svg {
          color: #920c00;
        }
      }
    }

    input[type=text], input[type=number], textarea, select {
        display: block;
        width: 50%;
        background: rgba(255, 255, 255, 0.746);
        border: 1px solid rgb(161, 161, 161);
        margin-bottom: 0.5rem;
        border-radius: 10px;
        padding: 0.5rem 1rem;
        font-size: 14px;
        outline: none;
        transition: border 0.3s;
        color: #24262a;
        -moz-appearance: textfield;

        &:focus {
            border: 1px solid #0a3a88;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=submit] {
        display: block;
        width: 25%;
        background: #0a3a88;
        color: #FFF;
        margin: 1rem 0;
        transition: 0.3s;
        padding: 0.3rem 1rem;
        border-radius: 10px;
        border: none;

        &:hover {
            background: #FFF;
            color: #0a3a88;
        }
    }

    .isShownLabel {
        padding-right: 1rem;
    }

    .projectPhotos {
        margin-top: 0.5rem;
        width: 50%;

        .projectPhotoContainer {
          display: inline-block;
          position: relative;

          .deleteButton {
            width: 16px;
            height: 16px;
            border-radius: 100%;
            background-color: #3d3d3d;
            position: absolute;
            right: 0px;
            cursor: pointer;
            transition: 0.3s;

            svg {
              color: #FFF;
              height: 7px;
              transition: 0.3s;
              margin-top: -7.5px;
              margin-left: 0.5px;
            }

            &:hover {
              background-color: #FFF;

              svg {
                color: #3d3d3d;
              }
            }
          }
        }

        .photo {
            width: 50px;
            height: 50px;
            object-fit: cover;
            margin: 0.2rem;
            border-radius: 10px;
            cursor: pointer;

            &.selected {
              outline: 3px solid #0a3a88;
            }
        }
    }

    .photoUploadBtn {
        width: 25%;
        background: #24262a;
        color: #FFF;
        border-radius: 10px;
        padding: 0.3rem 1rem;
        border: none;
        transition: 0.3s;
        margin-top: 0.5rem;

        &:hover {
            background: #777;
        }
    }

    h4 {
      font-size: 1em;
      font-weight: 600;
    }
}

.modal-dialog {
    position: absolute;
    top: 25%;
    left: 40%;
    transform: translate(-50%);
}

.deleteProjectBtn {
    color: #920c00;
    cursor: pointer;
}

.deleteProjectBtn:hover {
    text-decoration: underline;
}

.backButton {
    cursor: pointer;
    color: #0a3a88;
    transition: 0.3s;
    padding-right: 6rem;
}

.backButton:hover {
    color: #326fc8;
}

.newProjectBtn {
    float: right;
    background: #0a3a88;
    color: #FFF;
    font-weight: 600;
    padding: 0.4rem 1rem;
    font-size: 14px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        background: #2b599c;
    }
}

.uploadingProject {
    width: 50%;
    position: relative;

    .spinner {
        margin-left: 50%;
        margin-bottom: 1rem;
    }

    p {
        padding-bottom: 0rem;
    }
}

.pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .prevPage, .nextPage {
        padding: 0.4rem 1rem;
        background: #3d3d3d;
        color: #FFF;
        border-radius: 10px;
        cursor: pointer;
        font-size: 14px;
        transition: 0.3s;

        &:hover {
            background: #2b599c;
        }
    }

    .actualPage {
        padding: 0.4rem 0.7rem;
        margin: 0 0.5rem;
        font-size: 14px;
        background: #3d3d3d;
        color: #FFF;
        border-radius: 10px;
    }
}

.messageDetail {
    .senderName {
        padding-top: 1rem;
    }

    .sendedAt {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 0;
    }

    .senderMail, .senderTel {
        padding: 0;
        margin: 0;

        a {
            font-weight: 600;
        }
    }

    .senderMessage {
        background: #ecf4ff;
        border-radius: 10px;
        padding: 1rem;
        display: inline-block;
        margin-top: 1rem;
    }
}